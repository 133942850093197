import React, { useEffect, useState } from "react";
import { RiSettingsLine } from "react-icons/ri";
import { TransactionCard } from "../../../components/Cards/TransactionCard";
import { BottomNav } from "../../../components/Nav/BottomNav";
import { Wallet } from "../../../components/Wallet";
import { InvestCard1 } from "../../../components/Cards/InvestCard";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getLogUserRequest,
  getPlansRequest,
  getUserInvestmentsRequest,
} from "../../../redux/action";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BsWhatsapp } from "react-icons/bs";

const Home = () => {
  const dispatch = useDispatch();

  const { history } = useHistory();

  const {
    getPlansSuccess,
    getPlansError,
    getPlansLoading,
    user,
    isLoggedIn,
    plans,
    getLogUserError,
    token,
    getLogUserLoading,
    getUserInvestmentsLoading,
    investments,
    getUserInvestmentsError,
  } = useSelector((state) => {
    const {
      success: { getPlans: getPlansSuccess },
      errors: {
        getPlans: getPlansError,
        getUserInvestments: getUserInvestmentsError,
        getLogUser: getLogUserError,
      },
    } = state.ajaxStatuses;

    const { getUserInvestmentsLoading, getPlansLoading, getLogUserLoading } =
      state.loadingIndicator;

    const { user, token, isLoggedIn } = state.userData;

    const { investments } = state.investmentData;

    const { plans } = state.planData;

    return {
      getPlansSuccess,
      getPlansError,
      getPlansLoading,
      user,
      isLoggedIn,
      plans,
      getLogUserError,
      token,
      getLogUserLoading,
      getUserInvestmentsLoading,
      investments,
      getUserInvestmentsError,
    };
  });

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/login");
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (getPlansError) {
      toast.error(getPlansError, {
        duration: 3000,
      });
    }
  }, [getPlansError]);

  useEffect(() => {
    if (getLogUserError) {
      toast.error(getLogUserError, {
        duration: 3000,
      });
    }
  }, [getLogUserError]);

  useEffect(() => {
    dispatch(getPlansRequest());

    dispatch(getLogUserRequest(token));
  }, [getPlansRequest, getLogUserRequest]);

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(user._id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  const [activeInvestment, setActiveInvestment] = useState(0);
  const [expectedReturn, setExpectedReturn] = useState(0);

  useEffect(() => {
    if (!getUserInvestmentsLoading) {
      let amountInvested = 0;
      let expectedInvested = 0;

      for (let i = 0; i < investments.length; i++) {
        if (investments[i].isActive) {
          amountInvested += investments[i].amount;
          expectedInvested += investments[i].expectedReturn;
        }
      }

      setActiveInvestment(amountInvested);
      setExpectedReturn(expectedInvested);
    }
  }, [getUserInvestmentsLoading]);

  const imgArray = [
    "/img/new/card/1.jpeg",
    "/img/new/card/2.jpeg",
    "/img/new/card/3.jpeg",
    "/img/new/card/4.jpeg",
    "/img/new/card/5.jpg",
  ];

  return (
    <div>
      <div className="container max-w-sm mx-auto relative h-screen overflow-y-scroll pb-8">
        <div className="relative">
          <div className="relative">
            {" "}
            <h4 className="font-body text-lg  px-4 pt-8  font-bold">
              START GROWING YOUR WEALTH
            </h4>
            <p className="text-gray-700 text-sm font-body px-4">
              ValrInvestmentTrade is a digital asset technology company, which
              engages in mining cryptocurrencies, with a focus on the blockchain
              ecosystem and the generation of digital assets.
            </p>
            <a
              href="https://wa.me/27736717646"
              className="text-white flex justify-center gap-x-2  items-center text-sm bg-green-500 mx-4 mt-3 py-2 px-2 rounded-3xl text-center w-[105px]"
            >
              Contact
              <BsWhatsapp />
            </a>
            {/* <img
              className="w-full  mt-5"
              src="/img/new/background/warehouse.jpeg"
            /> */}
          </div>
        </div>

        <div className="px-4">
          <Wallet
            activeInvestment={activeInvestment}
            expectedReturn={expectedReturn}
            user={user}
            isHome={true}
            getUserInvestmentsLoading={getUserInvestmentsLoading}
            investments={investments}
          />

          {/* <div className="my-6">
          <p className="font-body text-base">Transaction History</p>

          <div className="flex flex-col gap-y-1 mt-4">
            <TransactionCard />
            <TransactionCard />
          </div>
        </div> */}

          <div className="mb-[100px] mt-10">
            <div className="text-xl font-body font-medium">
              Get Started Investing
            </div>

            <div className="mt-10">
              {getPlansLoading ? (
                <div className="h-[20vh] flex items-center justify-center text-center">
                  <CircularProgress size={20} className="text-primary" />
                </div>
              ) : (
                <div className="grid grid-cols-2 mt-10 gap-3">
                  {plans.map((plan, index) => (
                    <InvestCard1
                      plan={plan}
                      key={index}
                      img={imgArray[index]}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" max-w-sm mx-auto w-full ">
        <BottomNav />
      </div>
    </div>
  );
};

export default Home;
